import React, { useState, useEffect, useContext } from "react";
import "./nav.css"; // Ensure this is scoped
import logo from "../../images/IGB_v2.png";
import phone from "../../images/telephone.png";
import { Link } from "react-router-dom";
import { Sling as Hamburger } from "hamburger-react";
import LoginContext from "../../contexts/LoginContext";
import Notification from "../notification/Notification"; // Import the Notification component
import BellIcon from "../notification/BellIcon";
import User from "../../images/user.png";

import SubHead from "./SubHead";

const Nav = () => {
  const { isOpen, setOpen } = useContext(LoginContext);
  const authStr = localStorage.getItem("auth");
  const authObj = JSON.parse(authStr);
  const token = authObj?.data?.access_token;
  const userId = authObj?.data?.userData?.id;
  const [showNotification, setShowNotification] = useState(false); // State to manage notification visibility
  const [unreadCount, setUnreadCount] = useState(0); // State to manage unread notification count

  useEffect(() => {
    // Simulate fetching notifications
    // const fetchNotifications = async () => {
    //   // Replace with actual API call
    //   const notifications = await getUserNotification(userId);
    //   setUnreadCount(notifications.data.filter(notification => !notification.read).length);
    // };
    // fetchNotifications();
  }, [userId]);

  const logoutHandler = () => {
    localStorage.removeItem("auth");
    window.location.reload();
  };

  return (
    <div className="custom-navbar-container  fixed top-0 w-full z-10 bg-black">
      <div className="custom-navbar navbar-admin lg:justify-between shadow-xl w-full flex items-center px-4 lg:px-0">
        <Link to="/" className="custom-logo flex items-center">
          <img src={logo} alt="Ironglass Byte Logo" className="h-14 lg:h-24" />
        </Link>
        <ul className="menu menu-horizontal p-0 hidden lg:flex">
          {/* <li>
            <Link
              to="/DashPage"
              className="btn mr-3 bg-black text-blue2 blue2-border hover-item-sm orbitron"
            >
              Dashboard
            </Link>
          </li> */}
          <li>
            <Link
              to="/"
              className="btn mr-3 bg-black text-blue2 blue2-border hover-item-sm orbitron"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/Services"
              className="btn mr-3 bg-black text-blue2 blue2-border hover-item-sm orbitron"
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="/calendly"
              className="btn mr-3 bg-black text-blue2 blue2-border hover-item-sm orbitron"
            >
              Contact
            </Link>
          </li>
          <li>
            <Link
              to="/Careers"
              className="btn mr-3 bg-black text-blue2 blue2-border hover-item-sm orbitron"
            >
              Careers
            </Link>
          </li>
        </ul>
        <div className="custom-navbar-contact flex items-center">
        {/* <span className="">
            <Link to="/Login">
              <img className="hidden lg:block lg:h-8" src={User} />
            </Link>
          </span>  */}

          <a
            href="tel:+1(929) 445-6122"
            className="text-blue2 flex text-3xl font-bold bg-black mr-4"
          >
            <span className="mr-[-45px]">
              <img className="hidden lg:block lg:h-9" src={phone} />
            </span>
            <span> (929) 445-6122 </span>
          </a>

        

          <div className="lg:hidden">
            <Hamburger toggled={isOpen} toggle={setOpen} color="#34b2fd" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
