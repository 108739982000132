import "./App.css";
import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./components/home.js";
import Calendly from "./components/Calendly";
import Intro from "./components/intro";
import LoginContext from "./contexts/LoginContext";
import AOS from "aos";
import "aos/dist/aos.css";
import Dapps from "./components/Dapps.js";
import UIUX from "./components/UIUX.js";
import Websites from "./components/Websites";
import Apps from "./components/Apps.js";
import ArtificialIntelligence from "./components/ArtificialIntelligence";
import Footer from "./components/Footer.js";
import { APP_STATIC_PATH } from "./Routes2";
import { Link } from "react-router-dom";
import Myparticle from "./components/Particless.js";
import Login from "./components/Auth/Login.js";
import Singup from "./components/Auth/Signup.js";
import Services from "./components/pages/Services.js";
import DashPage from "./components/Dasboard/DashPage.js";
import Nav from "./components/navbars/Header.js";
import Software_page from "./components/pages/Software_page.js";
import Audio_page from "./components/pages/Audio_page.js";
import Legal_page from "./components/pages/Legal_page.js";
import Investment_page from "./components/pages/Investment_page.js";
import Documents from "./components/Dasboard/Documents.js";
import AdminUpload from "./components/admin/AdminUpload.js";
import AdminOverview from "./components/admin/AdminOverviwe.js";
import Notification from "./components/notification/Notification.js";
import Growth from "./components/pages/Growth.js";
import Sales from "./components/pages/growth/Sales.js";
import SEO from "./components/pages/growth/SEO.js";
import Staffing from "./components/pages/growth/Staffing.js";
import Ad_Market from "./components/pages/growth/Ad_Market.js";
import Content_Market from "./components/pages/growth/Content_Market.js";
import HrPay from "./components/pages/growth/HrPay.js";
import Hardware_Prototype from "./components/Hardware_Prototype.js";
import Cyber_Security from "./components/Cyber_Security.js";
import Patents from "./components/pages/Law/Patents.js";
import Legal_Documents from "./components/pages/Law/Legal_Documents.js";
import Litigations from "./components/pages/Law/Litigations.js";
import Entity_Setup from "./components/pages/Law/Entity_Setup.js";
import Discovery from "./components/pages/Law/Discovery.js";
import Mixing from "./components/pages/production/Mixing.js";
import Production from "./components/pages/production/Production.js";
import Mastering from "./components/pages/production/Mastering.js";
import Finance_page from "./components/pages/Finance_page.js";
import Accounts from "./components/pages/finance/Accounts.js";
import Taxes from "./components/pages/finance/Taxes.js";
import GptSB from "./components/GPT/ByteGpt.js";
import CareerForm from "./components/Careers.js";
import SquarePaymentForm from "./components/Paymets/Payments.js";

function App() {
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const authStr = localStorage.getItem("auth");
  const authObj = JSON.parse(authStr);
  const token = authObj?.data?.access_token;

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [isOpen]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 4500);
    window.scrollTo(0, 0);
  }, []);

  return (
    <LoginContext.Provider value={{ isOpen, setOpen }}>
      <div className="App">
        {/* <Myparticle /> */}
        <Nav />
        {isOpen && (
          <div id="nav-menu" data-aos="slide-right" data-aos-duration="500">
            <ul tabIndex={0}>
              <li>
                <Link
                  className="mr-3 text-blue2 orbitron bg-black"
                  to="/"
                  onClick={() => setOpen(false)}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  className="mr-3 text-blue2 orbitron bg-black"
                  to="/Services"
                  onClick={() => setOpen(false)}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  className="mr-3 text-blue2 orbitron bg-black"
                  to="/calendly"
                  onClick={() => setOpen(false)}
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  className="mr-3 text-blue2 orbitron bg-black"
                  to="/Careers"
                  onClick={() => setOpen(false)}
                >
                  Careers
                </Link>
              </li>
            </ul>
          </div>
        )}
        {isLoading ? (
          <Intro />
        ) : (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path={APP_STATIC_PATH.Dapps} element={<Dapps />} />
            <Route path={APP_STATIC_PATH.Apps} element={<Apps />} />
            <Route path={APP_STATIC_PATH.UIUX} element={<UIUX />} />
            <Route path={APP_STATIC_PATH.Websites} element={<Websites />} />
            <Route
              path={APP_STATIC_PATH.ArtificialIntelligence}
              element={<ArtificialIntelligence />}
            />
            <Route
              path={APP_STATIC_PATH.Hardware_Prototype}
              element={<Hardware_Prototype />}
            />
            <Route
              path={APP_STATIC_PATH.Cyber_Security}
              element={<Cyber_Security />}
            />

            <Route path={APP_STATIC_PATH.Calendly} element={<Calendly />} />
            <Route path={APP_STATIC_PATH.Login} element={<Login />} />
            <Route path={APP_STATIC_PATH.Signup} element={<Singup />} />
            <Route path={APP_STATIC_PATH.Documents} element={<Documents />} />

            {token ? (
              <Route path={APP_STATIC_PATH.DashPage} element={<DashPage />} />
            ) : (
              <Route
                path={APP_STATIC_PATH.DashPage}
                element={<Navigate to="/Login" replace />}
              />
            )}

            <Route path={APP_STATIC_PATH.Services} element={<Services />} />
            <Route
              path={APP_STATIC_PATH.AdminUpload}
              element={<AdminUpload />}
            />
            <Route
              path={APP_STATIC_PATH.AdminOverview}
              element={<AdminOverview />}
            />

            <Route path="*" element={<Home />} />

            <Route
              path={APP_STATIC_PATH.Software_page}
              element={<Software_page />}
            />
            <Route path={APP_STATIC_PATH.Audio_page} element={<Audio_page />} />
            <Route
              path={APP_STATIC_PATH.Finance_page}
              element={<Finance_page />}
            />
            <Route path={APP_STATIC_PATH.Legal_page} element={<Legal_page />} />
            <Route path={APP_STATIC_PATH.Growth_page} element={<Growth />} />
            <Route
              path={APP_STATIC_PATH.Investment_page}
              element={<Investment_page />}
            />
            {/* sub services */}

            {/* Growth    */}
            <Route path={APP_STATIC_PATH.Sales} element={<Sales />} />
            <Route path={APP_STATIC_PATH.SEO} element={<SEO />} />
            <Route path={APP_STATIC_PATH.Staffing} element={<Staffing />} />
            <Route
              path={APP_STATIC_PATH.AD_Marketing}
              element={<Ad_Market />}
            />
            <Route
              path={APP_STATIC_PATH.Content_Marketing}
              element={<Content_Market />}
            />
            <Route path={APP_STATIC_PATH.Hr_Pay} element={<HrPay />} />

            {/* Law */}
            <Route path={APP_STATIC_PATH.Patents} element={<Patents />} />
            <Route
              path={APP_STATIC_PATH.Entity_Setup}
              element={<Entity_Setup />}
            />
            <Route path={APP_STATIC_PATH.Discovery} element={<Discovery />} />
            <Route
              path={APP_STATIC_PATH.Legal_Documents}
              element={<Legal_Documents />}
            />
            <Route
              path={APP_STATIC_PATH.Litigations}
              element={<Litigations />}
            />

            {/* Production */}

            <Route path={APP_STATIC_PATH.Mixing} element={<Mixing />} />
            <Route path={APP_STATIC_PATH.Production} element={<Production />} />
            <Route path={APP_STATIC_PATH.Mastering} element={<Mastering />} />

            {/* Finance_page */}
            <Route path={APP_STATIC_PATH.Accounts} element={<Accounts />} />
            <Route path={APP_STATIC_PATH.Taxes} element={<Taxes />} />

            <Route path={APP_STATIC_PATH.Careers} element={<CareerForm />} />

            <Route
              path={APP_STATIC_PATH.Payments}
              element={<SquarePaymentForm />}
            />
          </Routes>
        )}
        <GptSB />
        <div className="page-container">
          {/* Your page content goes here */}
          <Footer />
        </div>
      </div>
    </LoginContext.Provider>
  );
}

export default App;
