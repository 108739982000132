import React from "react";
import test1 from "../../images/Untitled design (46).png";
import test6 from "../../images/Untitled design (44).png";
import test3 from "../../images/Untitled design (47).png";
import test4 from "../../images/Untitled design (45).png";
import "./software/soft.css";

const Testimonials = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 bg-black">
      <div className="w-full max-w-screen-lg rounded-lg p-2 mx-5 lg:p-10">
        <h1 className="text-5xl lg:text-5xl  z-50 text-[#C0C0C0] flex justify-center mb-4 lg:mb-10 orbitron">
          Testimonials
        </h1>
        <div className="flex flex-col lg:flex-row border border-b-purple-200 border-blue-500 card w-full mx-auto items-center justify-between">
          <div className="w-full lg:w-1/2 p-4 flex flex-col justify-center">
            <p className="text-xl lg:text-3xl   text-blue2 font-bold mb-4 lg:mb-7">
              Many success stories with Ironglass. Our expert services drive
              measurable results, helping businesses achieve and exceed their
              goals.
              <p className="text-lg lg:text-xl   text-gray-300 font-bold my-4   lg:mb-7">
                Build your own success stories with Ironglass. Our expert
                services drive your business to new heights.
              </p>
            </p>
          </div>
          <div className="w-full lg:w-1/2 p-4 flex justify-center items-center">
            <div className="img_carousel w-full mt-8 lg:mt-0 flex justify-center">
              <div className="img_carousel-container">
                <div className="img_carousel-slide flex justify-center">
                  <img className="p-2" src={test6} alt="Success story 1" />
                </div>
                <div className="img_carousel-slide flex justify-center">
                  <img className="p-2" src={test1} alt="Success story 1" />
                </div>
                <div className="img_carousel-slide flex justify-center">
                  <img className="p-2" src={test3} alt="Success story 2" />
                </div>
                <div className="img_carousel-slide flex justify-center">
                  <img className="p-2" src={test4} alt="Success story 3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
