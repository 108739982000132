import React from "react";
import gitIcon from "../../images/user.png";

const Hardware = () => {
  return (
    <div>
      {" "}
      <div className="flex p-2 m-2">
        <button className="btn mr-3 bg-black text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-black hover:border-black rounded-lg w-32 h-10 font-orbitron">
          Hardware
        </button>
        <div className="w-full border border-blue-500 rounded-lg flex flex-wrap">
          <div className="grid grid-cols-6 gap-4 p-2">
            <div className="col-span-1">
              <h1 className="font-bold text-blue2 p-3">6G Data_Cast</h1>
            </div>
            <div className="col-span-4 flex items-center">
              <p className="font-bold p-3 w-full h-10">
                webflix_GPT is a video streaming app made by us, fully
                interactive and dynamic to xyz client
              </p>
            </div>
            <div className="col-span-1 flex items-center justify-end">
              <img src={gitIcon} className=" w-8" />
              <a
                href="https://github.com/Chandan-Alimilli/webflix_GPT"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="font-bold p-3">github repository</button>
              </a>
            </div>
          </div>

          {/* Other list items */}
        </div>
      </div>
    </div>
  );
};

export default Hardware;
