import React from "react";
import intro3 from "../videos/igb-home.gif";

export default (props) => {
  return (
    <div className="w-screen pb-36 mt-0 lg:mt-[-125px]">
          
      <div className=" card lg:h-[45rem] px-30">
        <img
          className=""
          src={intro3}
          autoPlay
          muted
          loop
          playsInline
        />
      </div>
    </div>
  );
};
