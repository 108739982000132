

import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import logo from "../images/IGB_v2.png";
import discord from "../images/discord.png";
import linkedin from "../images/linkedin.png";
import whatsapp from "../images/whatsapp.png";

const Footer = () => {
  return (
    <footer className="new-footer card">
      <div className="footer-top">
        <div className="footer-logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="footer-contact">
          <p>
            Email:{" "}
            <a
              href="https://mail.google.com/mail/?view=cm&fs=1&to=Jake.ceo@ironglassbyte.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Jake.ceo@ironglassbyte.com
            </a>
          </p>
          <p>
            Phone: <a href="tel:+1(929)445-6122">(929) 445-6122</a>
          </p>
        </div>
        <div className="footer-socials">
          <a
            href="https://discord.gg/ssUpZZmrXn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={discord} alt="Discord" className="social-icon" />
          </a>
          <a
            href="https://www.linkedin.com/company/ironglass/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedin} alt="LinkedIn" className="social-icon" />
          </a>
          <a
            href="https://wa.link/9hnecl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={whatsapp} alt="whatsapp" className="social-icon" />
          </a>
        </div>
      </div>
      <div className="footer-links">
        <div className="footer-section">
          <h3 className="orbitron ">Growth</h3>
          <ul>
            <li>
              <Link to="/AD_Marketing">Ad Marketing</Link>
            </li>
            <li>
              <Link to="/Staffing">Staffing</Link>
            </li>
            <li>
              <Link to="/Content_Marketing">Content Marketing</Link>
            </li>
            <li>
              <Link to="/Sales">Sales</Link>
            </li>
            <li>
              <Link to="/Hr_Pay">HR Payrolls</Link>
            </li>
            <li>
              <Link to="/SEO">SEO</Link>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h3 className="orbitron">Production</h3>
          <ul>
            <li>
              <Link to="/Mixing">Mixing</Link>
            </li>
            <li>
              <Link to="/Mastering">Mastering</Link>
            </li>
            <li>
              <Link to="/Production">Music Production</Link>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h3 className="orbitron">Software</h3>
          <ul>
            <li>
              <Link to="/Dapps">Blockchain</Link>
            </li>
            <li>
              <Link to="/Cyber_Security">Cyber Security</Link>
            </li>
            <li>
              <Link to="/Websites">Web Design</Link>
            </li>
            <li>
              <Link to="/ArtificialIntelligence">Artificial Intelligence</Link>
            </li>
            <li>
              <Link to="/Apps">Apps</Link>
            </li>
            <li>
              <Link to="/Hardware_Prototype">Hardware Prototype</Link>
            </li>
            <li>
              <Link to="/UIUX">UI/UX</Link>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h3 className="orbitron">Finance</h3>
          <ul>
            <li>
              <Link to="/Accounts">Accounts</Link>
            </li>
            <li>
              <Link to="/Taxes">Taxes</Link>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h3 className="orbitron">Legal</h3>
          <ul>
            <li>
              <Link to="/Patents">Patents</Link>
            </li>
            <li>
              <Link to="/Discovery">Discovery</Link>
            </li>
            <li>
              <Link to="/Entity_Setup">Entity Setup</Link>
            </li>
            <li>
              <Link to="/Legal_Documents">Legal Documents</Link>
            </li>
            <li>
              <Link to="/Litigations">Litigations</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Ironglass. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
