import React, { useState } from "react";
import jake from "../images/jake.png";
import osaze from "../images/osaze.png";
import linkedinIcon from "../images/linkedin.png";
import "./leader.css"

const leaders = [
  {
    name: "Jake Hitzges",
    position: "Chief Executive Officer",
    summary:
      "Jake Hitz is an experienced business man and software engineer. After graduating with his computer science degree from James Madison University, Jake went on to develop products in the web3, artificial intelligence and cyber security sectors. Acting as a project manager allowed him to oversee teams of engineers, network with industry professionals, and work alongside some of the biggest brands in blockchain. As CEO of Ironglass Byte he mainly focused on driving the company vision of building the future.",
    imageUrl: jake,
    social: {
      linkedin: "https://www.linkedin.com/in/jacob-hitzges-624b36171/",
    },
  },
  {
    name: "Osaze Moore",
    position: "Chief Technical Officer",
    summary:
      "Osaze Moore is an experienced software engineer who spent years building out cybersecurity systems for the US government. While doing cybersecurity for the government he also spent time mastering blockchain and AI engineering and soon became CTO of Ironglass Byte. At Ironglass Byte he stays up to date on the latest technology and manages teams of engineers dedicated to building the future.",
    imageUrl: osaze,
    social: {
      linkedin: "https://www.linkedin.com/in/osaze-moore-451725168/",
    },
  },
];

const Leadership = () => {
  return (
    <div className="leadership-container">
      <h2 className="leadership-title orbitron ">Meet the Leaders</h2>
      <div className="leaders-wrapper">
        {leaders.map((leader, index) => (
          <LeaderItem key={index} leader={leader} />
        ))}
      </div>
    </div>
  );
};

const LeaderItem = ({ leader }) => {
  const [hover, setHover] = useState(false);

  return (
    <div className="leader-item card">
      <div
        className={`leader-image-container ${hover ? "hover" : ""}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <img src={leader.imageUrl} alt={leader.name} className="leader-image" />
      </div>
      <div className="leader-info">
        <h3 className="leader-name">{leader.name}</h3>
        <p className="leader-position">{leader.position}</p>
        <p className="leader-summary">{leader.summary}</p>
        <div className="social-icons">
          <a
            href={leader.social.linkedin}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedinIcon} alt="LinkedIn" className="social-icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Leadership;
