

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import audio from "../../images/igp.png";
import finance from "../../images/igf.png";
import legal from "../../images/igl.png";
import Investment from "../../images/igi.png";
import growth from "../../images/igg.png";
import Software from "../../images/igb.png";
import byte from "../../images/sh.png";
import igi from "../../images/ih.png";
import growth_home from "../../images/gh.png";
import law_home from "../../images/lh.png";
import finance_home from "../../images/fh.png";
import audio_home from "../../images/ah.png";
import "./ServicesHome.css"; // Import the new CSS file for ServicesHome
import Myparticle from "../Particless";

const Services = () => {
  const [currentBubble, setCurrentBubble] = useState(0);
  const navigate = useNavigate();

  const bubbles = [
    {
      id: "growth",
      to: "/Growth_page",
      imgSrc: growth,
      description: "Growth Services",
      summary:
        "We provide strategies and solutions to grow your business efficiently.",
      showcaseImg: growth_home,
      showcaseContent:
        "Our Growth Services are designed to accelerate your business by identifying key market opportunities. We create tailored strategies that help businesses reach new audiences and increase their market share. From innovative marketing techniques to data-driven decision-making, we lay the foundation for sustainable growth.",
      additional1: "Increase market reach.",
      additional2: "Boost sales and revenue.",
      color: "#3bb24d",
    },
    {
      id: "software",
      to: "/Software_page",
      imgSrc: Software,
      description: "Software Services",
      summary:
        "Expert software development and IT solutions for your business needs.",
      showcaseImg: byte,
      showcaseContent:
        "Our expertise in software development is showcased through cutting-edge projects like 6G Broadcast, a revolutionary platform that pushes the boundaries of connectivity. With Hera, we developed an intelligent system that enhances operational efficiency. Our team excels in delivering robust software solutions tailored to meet the unique needs of your business.",
      additional1: "Custom software solutions.",
      additional2: "Innovative IT services.",
      color: "#0380ca",
    },
    {
      id: "audio",
      to: "/Audio_page",
      imgSrc: audio,
      description: "Audio Services",
      summary: "High-quality audio production and mastering for your projects.",
      showcaseImg: audio_home,
      showcaseContent:
        "Our Audio Services team has worked on high-profile projects, delivering top-notch audio production and mastering. Whether it's creating immersive soundscapes or finely-tuned audio tracks, our work speaks for itself. We bring creativity and precision to every project, ensuring your audio needs are met with the highest standards.",
      additional1: "Professional mixing.",
      additional2: "Creative sound design.",
      color: "#dc2525",
    },
    {
      id: "investment",
      to: "https://ironglassinvestment.com/#/LearnMore",
      imgSrc: Investment,
      description: "Ironglass Investment",
      summary:
        "Secure and profitable investment opportunities for your financial growth.",
      showcaseImg: igi,
      showcaseContent:
        "With our deep expertise in the investment sector, we've developed a user-friendly investing app that empowers users to make informed decisions. Our tailored investment strategies are designed to maximize returns while managing risk. Whether you’re a novice investor or a seasoned pro, our tools and insights will help you navigate the complex world of finance.",
      additional1: "High return rates.",
      additional2: "Diverse investment options.",
      color: "gold",
    },
    {
      id: "legal",
      to: "/Legal_page",
      imgSrc: legal,
      description: "Legal Services",
      summary:
        "Comprehensive legal services to protect and support your business.",
      showcaseImg: law_home,
      showcaseContent:
        "Our Legal Services team has successfully navigated complex legal landscapes, providing clients with reliable representation in various cases. From business litigation to contract negotiations, we ensure your interests are protected. Our legal experts are committed to delivering practical solutions that meet your unique business needs.",
      additional1: "Expert legal advice.",
      additional2: "Reliable representation.",
      color: "#c8944f",
    },
    {
      id: "finance",
      to: "/Finance_page",
      imgSrc: finance,
      description: "Finance Services",
      summary: "Professional financial management and consulting services.",
      showcaseImg: finance_home,
      showcaseContent:
        "Our Finance Services are designed to provide you with insightful financial management and consulting. We help businesses make informed decisions through detailed financial analysis and strategic planning. Whether you're looking to optimize your financial operations or plan for the future, our services are tailored to help you succeed.",
      additional1: "Accurate financial analysis.",
      additional2: "Strategic financial planning.",
      color: "#F4C32C",
    },
  ];

  const handleClick = (buttonId, to) => (evt) => {
    evt.preventDefault();
    if (buttonId === "investment") {
      window.open(to, "_blank"); // Open the investment link in a new tab
    } else {
      navigate(to); // Navigate to other pages
    }
  };

  const handleNext = () => {
    setCurrentBubble((prev) => (prev + 1) % bubbles.length);
  };

  const handlePrev = () => {
    setCurrentBubble((prev) => (prev - 1 + bubbles.length) % bubbles.length);
  };

  useEffect(() => {
    const interval = setInterval(handleNext, 5000); // Auto-scroll every 5 seconds
    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  const renderButton = (to, imgSrc, imgClass, buttonId) => (
    <div
      key={buttonId}
      className={`btn service-bubble ${
        currentBubble === buttonId ? "focused" : ""
      }`}
      onClick={handleClick(bubbles[buttonId].id, to)} // Pass the bubble's id to handleClick
    >
      <img src={imgSrc} className={imgClass} alt="Service Icon" />
    </div>
  );

  return (
    <div className="services-container  py-3 px-5">
      <Myparticle />
      <p className="services-heading">Explore Our Services</p>
      <div className="bubble-navigation">
        <div className="bubble-wrapper">
          {bubbles.map((bubble, index) =>
            renderButton(bubble.to, bubble.imgSrc, "bubble-img", index)
          )}
        </div>
      </div>
      <div className="bubble-description-container">
        <button className="nav-button prev" onClick={handlePrev}>
          ←
        </button>
        <div className="description-summary">
          <div
            className="description"
            style={{
              color: bubbles[currentBubble].color,
              fontFamily: "orbitron",
              fontSize: "1.8rem",
            }}
          >
            {bubbles[currentBubble].description}
          </div>
          <div className="summary" style={{ color: "#C0C0C0" }}>
            {bubbles[currentBubble].summary}
          </div>
          {/* New Showcase Section */}
          <div className="showcase-section border-[3px] border-b-[#F4C32C] border-r-[#dc2525] border-l-[#3bb24d] border-t-[#0380ca]">
            <img
              src={bubbles[currentBubble].showcaseImg}
              alt="Showcase"
              className="showcase-img"
            />
            <div className="grid">
              <div
                className="description"
                style={{
                  color: bubbles[currentBubble].color,
                  fontSize: "1.8rem",
                }}
              >
                {bubbles[currentBubble].description}
              </div>
              <p className="showcase-content">
                {bubbles[currentBubble].showcaseContent}
              </p>
            </div>
          </div>
          {/* End of Showcase Section */}
          {/* <div className="additional-info" style={{ color: "#C0C0C0" }}>
            <div>{bubbles[currentBubble].additional1}</div>
            <div>{bubbles[currentBubble].additional2}</div>
          </div> */}
        </div>
        <button className="nav-button next" onClick={handleNext}>
          →
        </button>
      </div>
    </div>
  );
};

export default Services;
