import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Testimonals from "../Testimonals";
import ContactButton from "../../ContactButton";
import production_video from "../../../videos/mp.gif";
import Production_FAQ from "./Production_FAQ.js";
import production from "../../../images/igp.png";
import Particless from "./Prodution_Particless.js";
import { getPaymentUrl } from "../../actions/payments.js";
import pro from "../../../images/production.gif";

const Production = () => {
  const handlePurchaseClick = async() =>{
    const type = 'production'
    const payload = {
      serviceType:type
    }
    const data = await getPaymentUrl(payload);
    console.log('data', data.data.checkoutPageUrl)
    if(data.data.checkoutPageUrl){
      window.open(data?.data?.checkoutPageUrl, '_blank', 'noopener,noreferrer');
    }
    
  }
  return (
    <div className="section ">
      <Helmet>
        <title>Music Production Services - Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte offers comprehensive music production services, from songwriting and recording to final production. Achieve top-quality sound and creative expression with our team."
        />
        <meta
          name="keywords"
          content="music production services, audio production, songwriting, music recording, sound engineering, music producers, Ironglass Byte"
        />
        <meta property="og:title" content="Music Production Services | Ironglass Byte" />
        <meta
          property="og:description"
          content="Bring your musical vision to life with Ironglass Byte's production services. We offer comprehensive support from songwriting to final production, ensuring top-quality sound."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/Production"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/Ironglass_Production_icon_stylized_v003.png"
        />
        <link rel="canonical" href="https://www.ironglassbyte.com/Production" />
      </Helmet>

      <Particless />

      {/* Main Container */}
      <div className="flex flex-col w-screen lg:flex-row min-w-full place-content-center mt-2 lg:mt-10 px-2 lg:px-0">
        
        {/* Top Content */}
        <div className="block mb-8">
          <div className="p-2 lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl lg:text-6xl mb-5 flex text-[#C0C0C0] p-2 lg:p-4 m-2 font-bold ">
              Production
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-2xl border-b-blue-300 card p-2 m-2 lg:m-4 border border-[#9c2525] rounded-xl bg-black">
                Ironglass Production's Production service covers every aspect of
                bringing your musical vision to life. From songwriting and arranging
                to recording and final production, we provide comprehensive support
                throughout the entire creative process. Our team of skilled
                producers and engineers works closely with you to capture the
                essence of your music and achieve the highest quality sound.
              </p>
              <img className="h-60 lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10" src={production} alt="Ironglass Production Icon" />
            </div>
          </div>

          {/* Video and Description Section */}
          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 lg:w-[100rem]">
              <img className="w-full h-auto" src={production_video} autoPlay muted loop playsInline />
            </div>

            <div className="w-full lg:w-[100%]">
              <p className="text-xl lg:text-2xl border-b-blue-300 card border border-[#9c2525] rounded-xl p-2 lg:p-4 m-2">
                We offer a collaborative environment where your ideas are valued and
                brought to fruition. Whether you're an independent artist or a full
                band, we tailor our production services to meet your specific needs
                and artistic goals. At Ironglass Production, we are committed to
                creating exceptional music that resonates with your audience and
                stands out in the industry.
              </p>
            </div>
          </div>

          {/* Pricing and Purchase Button Section */}
          <div className="flex flex-col card border border-b-blue-300 border-[#dc2525] items-center justify-center my-6">
          
            <h1 className="text-3xl lg:text-4xl font-bold ">
              Take your music to the next level with our full-scale production services for $800.
            </h1>
            <img src={pro} className="h-56"/>
            <p className="text-xl lg:text-2xl text-center mb-6">
              "At **$800**, we offer comprehensive support from concept to completion."
            </p>
            <button  onClick={handlePurchaseClick} className="bg-[#9c2525] text-white py-3 px-6 rounded-lg text-xl">
           
              Purchase Now - $800
            </button>


            <button className="bg-[#C0C0C0] text-[#000000] py-3 px-6 rounded-lg text-xl">
            <Link
              to="/calendly"
             
            >
              Book a call with our expert
            </Link>
            </button>
          </div>

          {/* Testimonials */}
          <Testimonals />

          {/* FAQ Section */}
          <div>
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl text-[#C0C0C0]">
              Frequently Asked Questions By Our Clients
            </h1>
            <Production_FAQ />
            <br />
            <br />
          </div>

          {/* Contact Section */}
          <br />
          <p className="text-center font-extrabold text-2xl lg:text-3xl text-[#C0C0C0]">
            Contact us for world-class Music Production services!
          </p>
          <br />
          <div className="mb-[-4rem]">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Production;
