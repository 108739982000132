import React from "react";
import igb from "../../images/IGB_v2.png";
import uiux from "../../videos/ui-ux.gif";
import ssub from "../../images/ssub.png";


const Software = () => {
  const handleIconClick = (url, e) => {
    window.open(url, "_blank");
  };

  return (
    <div className="flex p-2 m-2 text-white">
      <button className="btn mr-3 bg-black text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-black hover:border-black rounded-lg  h-10 font-orbitron col">
      <img src={igb} className="h-32 w-auto"/>
        {/* Software */}
      </button>

      <div className="w-full border border-blue-500 rounded-lg">
        
<div className="flex justify-center">

        {/* <img src={sh} className="h-40 w-auto"/> */}
</div>

<div className="  p-2 text-blue2 ">
  <div className="mt-10"></div>
 
  <div className="flex  text-2xl font-bold mt-5 ">
    <div>
    <img src={ssub} className=" mr-9  h-36 "/>
    <h1 className="mt-[-35px] ml-10  ">Blockchain</h1>
    </div>

    <div>
    <img src={uiux} className=" h-44 "/>
  
    </div>
    <div>
    <button className="p-2 text-l mr-10"> Buy Now</button>
    </div>
    
  </div><div className="flex  text-2xl font-bold mt-5 ">
    <div>
    <img src={ssub} className=" mr-9  h-36 "/>
    <h1 className="mt-[-35px] ml-6  ">Websites</h1>
    </div>

    <div>
    <img src={uiux} className=" h-44 "/>
  
    </div>
    <div>
    <button className="p-2 text-l mr-10"> Buy Now</button>
    </div>
    
  </div>

  <div className="flex  text-2xl font-bold mt-5 ">
    <div>
    <img src={ssub} className=" mr-9  h-36 "/>
    <h1 className="mt-[-35px] ml  ">Cyber Security</h1>
    </div>

    <div>
    <img src={uiux} className=" h-44 "/>
  
    </div>
    <div>
    <button className="p-2 text-l mr-10"> Buy Now</button>
    </div>
    
  </div>

  <div className="flex  text-2xl font-bold mt-5 ">
    <div>
    <img src={ssub} className=" mr-9  h-36 "/>
    <h1 className="mt-[-35px] ml-10  ">UIUX</h1>
    </div>

    <div>
    <img src={uiux} className=" h-44 "/>
  
    </div>
    <div>
    <button className="p-2 text-l mr-10"> Buy Now</button>
    </div>
    
  </div>

  <div className="flex  text-2xl font-bold mt-5 ">
    <div>
    <img src={ssub} className=" mr-9  h-36 "/>
    <h1 className="mt-[-35px] ml-10  ">UIUX</h1>
    </div>

    <div>
    <img src={uiux} className=" h-44 "/>
  
    </div>
    <div>
    <button className="p-2 text-l mr-10"> Buy Now</button>
    </div>
    
  </div>
  <div className="flex  text-2xl font-bold mt-5 ">
    <div>
    <img src={ssub} className=" mr-9  h-36 "/>
    <h1 className="mt-[-35px] ml-10  ">UIUX</h1>
    </div>

    <div>
    <img src={uiux} className=" h-44 "/>
  
    </div>
    <div>
    <button className="p-2 text-l mr-10"> Buy Now</button>
    </div>
    
  </div>


  <div className="flex  text-2xl font-bold mt-5 ">
    <div>
    <img src={ssub} className=" mr-9  h-36 "/>
    <h1 className="mt-[-35px] ml-10  ">UIUX</h1>
    </div>

    <div>
    <img src={uiux} className=" h-44 "/>
  
    </div>
    <div>
    <button className="p-2 text-l mr-10"> Buy Now</button>
    </div>
    
  </div>
</div>


       
      </div>
    </div>
  );
};

export default Software;
