import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Testimonals from "../Testimonals";
import ContactButton from "../../ContactButton";
import production_video from "../../../videos/mixing.gif";
import production from "../../../images/igp.png";
import Mixing_FAQ from "./Mixing_FAQ";
import Particless from "./Prodution_Particless.js";
import { getPaymentUrl } from "../../actions/payments.js";
import AfterPurchaseForm from "../../AfterPurchaseForm.js";
import pro from "../../../images/production.gif";


const Mixing = () => {
  const handlePurchaseClick = async() =>{
    const type = 'mixing'
    const payload = {
      serviceType:type
    }
    const data = await getPaymentUrl(payload);
    console.log('data', data.data.checkoutPageUrl)
    if(data.data.checkoutPageUrl){
      window.open(data?.data?.checkoutPageUrl, '_blank', 'noopener,noreferrer');
    }
    
  }
  return (
    <div className="section "> 
      <Helmet>
        <title>Mixing Services | Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte offers professional Mixing services to ensure your audio tracks are balanced, polished, and ready for mastering. Enhance your music with expert mixing."
        />
        <meta
          name="keywords"
          content="mixing services, music mixing, audio production, sound engineering, music polishing, mixing engineers, Ironglass Byte"
        />
        <meta property="og:title" content="Mixing Services | Ironglass Byte" />
        <meta
          property="og:description"
          content="Achieve professional sound quality with Ironglass Byte's Mixing services. Our engineers balance your tracks to ensure clarity, depth, and spatial consistency."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/Mixing"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/Ironglass_Production_icon_stylized_v003.png"
        />
        <link rel="canonical" href="https://www.ironglassbyte.com/Mixing" />
      </Helmet>

      <Particless />


      {/* <AfterPurchaseForm/> */}

      {/* Main Container */}
      <div className="flex flex-col w-screen lg:flex-row min-w-full place-content-center mt-2 lg:mt-10 px-2 lg:px-0">
        
        {/* Top Content */}
        <div className="block mb-8">
          <div className="p-2 lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl  lg:text-6xl mb-5 flex text-[#C0C0C0] p-2 lg:p-4 m-2 font-bold ">
              Mixing
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-2xl border-b-blue-300 card p-2 m-2 lg:m-4 border border-[#9c2525] rounded-xl bg-black">
                At Ironglass Production, our Mixing service ensures that your audio
                tracks are balanced, polished, and ready for mastering. Our expert
                engineers use state-of-the-art equipment and software to blend
                individual audio elements seamlessly, creating a cohesive and
                professional sound. We focus on enhancing clarity, depth, and
                spatial balance, bringing your music to life. Whether it's adjusting
                levels, EQ, or adding effects, our goal is to deliver a mix that
                meets your artistic vision and industry standards.
              </p>
              <img
                className="h-60 lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10"
                src={production}
                alt="Ironglass Production Icon"
              />
            </div>
          </div>

          {/* Video and Description Section */}
          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 lg:w-[100rem]">
              <img className="w-full h-auto" src={production_video} autoPlay muted loop playsInline />
            </div>

            <div className="w-full lg:w-[100%]">
              <p className="text-xl lg:text-2xl border-b-blue-300 card border border-[#9c2525] rounded-xl p-2 lg:p-4 m-2">
                Our team works closely with you to understand your creative
                direction and preferences. We provide detailed revisions and
                feedback to ensure that the final mix aligns perfectly with your
                expectations. With Ironglass Production, you can trust that your
                music will sound its best, ready to captivate audiences. Our
                commitment to quality and attention to detail makes us a trusted
                partner in your musical journey.
              </p>
            </div>
          </div>

          {/* Pricing and Purchase Button Section */}
          <div className="flex flex-col card border-b-blue-300 border border-[#dc2525] items-center justify-center my-6">
            <h1 className="text-3xl lg:text-4xl font-bold ">
              Bring your tracks to life with our professional mixing services for just $200.
            </h1>
            <img src={pro} className="h-56"/>
            <p className="text-xl lg:text-2xl text-center mb-6">
              "For just **$200**, we'll ensure your sound is perfectly balanced and polished to perfection."
            </p>
            <button className="bg-[#9c2525] text-white py-3 px-6 rounded-lg text-xl" onClick={handlePurchaseClick}>
            Purchase Now - $200
              
            </button>

            <button className="bg-[#C0C0C0] text-[#000000] py-3 px-6 rounded-lg text-xl">
            <Link
              to="/calendly"
             
            >
              Book a call with our expert
            </Link>
            </button>
          </div>

          {/* Testimonials */}
          <Testimonals />

          {/* FAQ Section */}
          <div>
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl text-[#C0C0C0]">
              Frequently Asked Questions By Our Clients
            </h1>
            <Mixing_FAQ />
            <br />
            <br />
          </div>

          {/* Contact Section */}
          <br />
          <p className="text-center font-extrabold text-2xl lg:text-3xl text-[#C0C0C0]">
            Contact us to create professional Mixing for your music!
          </p>
          <br />
          <div className="mb-[-4rem]">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mixing;
