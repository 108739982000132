import React, { useState } from "react";
import emailjs from "emailjs-com"; // Import EmailJS
import Particless from "./Particless.js";
import { Link } from "react-router-dom";
import igb from "../videos/intro.gif";
import "./CareerForm.css"; // Use the same CSS style as Quick Contact

const CareerForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    sector: "",
    jobProfile: "",
    experience: "",
    position: "",
    resume: null,
    profile: "",
  });

  const [submitted, setSubmitted] = useState(false); // State to handle form submission

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, resume: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare the email parameters
    const templateParams = {
      name: formData.name,
      email: formData.email,
      sector: formData.sector,
      jobProfile: formData.jobProfile,
      experience: formData.experience,
      position: formData.position,
      profile: formData.profile || "N/A", // Optional field
    };

    // Send the email using EmailJS
    emailjs
      .send(
        "service_x1p7leb", 
        "template_pzdnh2w", 
        templateParams,
        "TVCzdDhJLcMZ7YUs8" 
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setSubmitted(true); // Set submission state to true
        },
        (err) => {
          console.error("FAILED...", err);
        }
      );
  };

  return (
    <div className="w-screen h-screen">
      <Particless />
      {submitted ? (
        <div className="thank-you-message  ">
          <img src={igb} alt="IGB Logo" className="igb-logo rounded-lg border border-blue-400 border-b-purple-700 card" />
            <p className="mb-6" >Thanks for your submission, we will reach you soon.</p>
          <button className=" card btn mr-3 bg-black text-blue2 blue2-border hover-item-sm orbitron">
            <Link
              to="/"
            >
              Back To Home
            </Link>
          </button>
          <div ></div>
        </div>
      ) : (
        <div className="career-form-container  card">
          <h2>Join Our Team</h2>
          <p>
            We are always looking for talented individuals to join our team.
            Please fill out the form below and we will be in touch shortly.
          </p>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="form-row">
              <input
                type="text"
                name="name"
                placeholder="Name *"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Email *"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-row">
              <input
                type="text"
                name="sector"
                placeholder="Sector *"
                value={formData.sector}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="jobProfile"
                placeholder="Job Profile *"
                value={formData.jobProfile}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-row">
              <input
                type="text"
                name="experience"
                placeholder="Experience (in years) *"
                value={formData.experience}
                onChange={handleChange}
                required
              />
              <select
                name="position"
                value={formData.position}
                onChange={handleChange}
                required
              >
                <option value="">Select Position *</option>
                <option value="intern">Intern</option>
                {/* <option value="developer">Developer</option> */}
                <option value="developer">Engineer</option>
                {/* <option value="seniorDeveloper">Senior Developer</option> */}
                {/* <option value="manager">Manager</option> */}
                <option value="manager">HR Manager</option>
                <option value="manager">Sales Manager</option>
                <option value="manager">Affiliate</option>
                <option value="manager">Others</option>
              </select>
            </div>
           


            <div className="form-row">
  <div className="file-input-wrapper">
    <button type="button" className="custom-file-button" onClick={() => document.getElementById('fileInput').click()}>
      Choose File
    </button>
    <input
      type="file"
      id="fileInput"
      className="file-input"
      name="resume"
      accept=".pdf"
      onChange={handleFileChange}
      required
    />
  </div>
  <input
    type="text"
    name="profile"
    placeholder="Profile (Optional)"
    value={formData.profile}
    onChange={handleChange}
  />
</div>


            <button className="bg-slate-800" type="submit">Submit Application</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default CareerForm;
