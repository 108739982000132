import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Testimonals from "../Testimonals";
import ContactButton from "../../ContactButton";
import production_video from "../../../videos/mastering.gif";
import production from "../../../images/igp.png";
import pro from "../../../images/production.gif";
import Particless from "./Prodution_Particless.js";
import Mastering_FAQ from "./Mastering_FAQ.js";
import Audio_profiles from "./Audio_profiles.js";
import { getPaymentUrl } from "../../actions/payments.js";

const Mastering = () => {
  const handlePurchaseClick = async () => {
    const type = "mastering";
    const payload = {
      serviceType: type,
    };
    const data = await getPaymentUrl(payload);
    console.log("data", data.data.checkoutPageUrl);
    if (data.data.checkoutPageUrl) {
      window.open(data?.data?.checkoutPageUrl, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <div className="section ">
      <Helmet>
        <title>Mastering Services - Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte offers professional mastering services to refine your music for the highest quality playback across platforms. Our engineers polish your tracks to industry standards."
        />
        <meta
          name="keywords"
          content="mastering services, music mastering, audio production, sound quality, music polishing, mastering engineers, Ironglass Byte"
        />
        <meta
          property="og:title"
          content="Mastering Services | Ironglass Byte"
        />
        <meta
          property="og:description"
          content="Achieve professional sound quality with Ironglass Byte's mastering services. We optimize your music for distribution with refined audio mastering."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/Mastering"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/Ironglass_Production_icon_stylized_v003.png"
        />
        <link rel="canonical" href="https://www.ironglassbyte.com/Mastering" />
      </Helmet>

      <Particless />

      {/* Main Container */}
      <div className="flex flex-col w-screen lg:flex-row min-w-full place-content-center mt-2 lg:mt-10 px-2 lg:px-0">
        {/* Top Content */}
        <div className="block mb-8">
          <div className="p-2 lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl lg:text-6xl mb-5 flex text-[#C0C0C0] p-2 lg:p-4 m-2 font-bold ">
              Mastering
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-2xl card p-2 m-2 lg:m-4 border border-[#dc2525] rounded-xl bg-black">
                At Ironglass Production, our Mastering service provides the
                final polish to your music, ensuring it meets the highest
                industry standards. Mastering enhances the overall sound
                quality, consistency, and loudness of your tracks, making them
                ready for distribution. Our experienced engineers use advanced
                tools and techniques to refine your mix, balancing the frequency
                spectrum and optimizing playback across all devices and
                platforms.
              </p>
              <img
                className="h-60 lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10"
                src={production}
                alt="Ironglass Production Icon"
              />
            </div>
          </div>

          {/* Video and Description Section */}
          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 lg:w-[100rem]">
              <img
                className="w-full h-auto"
                src={production_video}
                autoPlay
                muted
                loop
                playsInline
              />
            </div>

            <div className="w-full lg:w-[100%]">
              <p className="text-xl lg:text-2xl card border border-[#9c2525] rounded-xl p-2 lg:p-4 m-2">
                We pay close attention to detail, making subtle adjustments that
                bring out the best in your music. Our goal is to provide a
                professional, radio-ready sound that translates well in any
                listening environment. With Ironglass Production, you can be
                confident that your music will stand out and compete in today's
                competitive market. Our mastering service ensures that your
                tracks are polished, cohesive, and ready to impress.
              </p>
            </div>
          </div>

          {/* Pricing and Purchase Button Section */}
          <div className="flex flex-col card border border-[#dc2525] items-center justify-center my-6">
            <h1 className="text-3xl lg:text-4xl font-bold ">
              Elevate your music with expert mastering for only $150.
            </h1> 
            <img src={pro} className=" h-56"/>
            <p className="text-xl lg:text-2xl text-center mb-6">
              "Let us give your tracks the clarity and punch they deserve for
              just $150."
            </p>
            <button
              onClick={handlePurchaseClick}
              className="bg-[#dc2525] text-white py-3 px-6 rounded-lg text-xl"
            >
              Purchase Now - $150
            </button>
            <button className="bg-[#C0C0C0] text-[#000000] py-3 px-6 rounded-lg text-xl">
              <Link to="/calendly">Book a call with our expert</Link>
            </button>
          </div>

          {/* Testimonials */}
          <Testimonals />

          {/* FAQ Section */}
          <div>
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl text-[#C0C0C0]">
              Frequently Asked Questions By Our Clients
            </h1>
            <Mastering_FAQ />
            <br />
            <br />
          </div>

          {/* Contact Section */}
          <br />
          <p className="text-center font-extrabold text-2xl lg:text-3xl text-[#C0C0C0]">
            Contact us to achieve the perfect sound with Mastering services!
          </p>
          <br />
          <div className="mb-[-4rem]">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mastering;
